<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>

  <div id="page">
    <Header :add_class="['header-auto-show']" />

    <Footer logged />
    <!-- Page Title-->
    <div class="page-title page-title-fixed">
      <a
        @click="$router.go(-1)"
        class="page-title-icon rounded-s ms-3 bg-theme color-theme"
      ><i class="fas fa-chevron-left"></i
      ></a>
      <h1 class="mx-auto font-18">Tukar Poin</h1>
      <a data-menu="menu-main" class="page-title-icon rounded-s ms-3"></a>
    </div>
    <div class="page-title-clear"></div>

    <!-- End Page Title-->

    <!-- Experience List-->
    <div class="page-content mt-4">
      <!-- Search Skill -->
      <div class="" data-card-height="250">
        <div class="px-3 mb-2">
          <div class="row">
            <div
              class="col-6 mb-4 d-flex align-items-stretch"
              v-for="option in redeemOptions"
              :key="option.id"
            >
              <div
                class="card mb-2 w-100"
                @click="actionRedeem(option)"
              >
                <button>
                  <div
                    class="card-body rounded bg-secondary d-flex justify-content-center align-items-center text-center"
                    v-if="this.historyPoint?.point < option.nominal"
                  >
                    <h6 class="text-white">
                      {{ option.name_item }}
                    </h6>
                  </div>
                  <div
                    class="card-body rounded bg-highlight d-flex justify-content-center align-items-center text-center"
                    v-else
                  >
                    <h6 class="text-white">
                      {{ option.name_item }}
                    </h6>
                  </div>
                  <div class="card-footer">
                    <div
                      class="text-center"
                    >
                      Tukar {{ new Intl.NumberFormat(['ban', 'id']).format(option.nominal ?? 0) }} poin
                    </div>
                  </div>
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- End Search Skill -->
      <!-- Skill List -->
      <div v-if="redeemOptions.length === 0" class="mb-5">
        <EmptyState
          :message1="'Belum ada riwayat poin,'"
          :message2="'undang sekarang !'"
          :cardCenter="false"
        />
      </div>
      <!-- Skill List -->
    </div>
    <!-- End Experience List-->

    <!-- Main Menu-->
    <div
      id="menu-main"
      class="menu menu-box-left rounded-0"
      data-menu-width="280"
      data-menu-active="nav-welcome"
    >
      <MenuMain @close="menuClose" />
    </div>

    <!-- Share Menu-->
    <div
      id="menu-share"
      class="menu menu-box-bottom rounded-m"
      data-menu-load="menu-share.html"
      data-menu-height="370"
    ></div>

    <!-- Colors Menu-->
    <div
      id="menu-colors"
      class="menu menu-box-bottom rounded-m"
      data-menu-load="menu-colors.html"
      data-menu-height="480"
    >
      <MenuColors @close="menuClose" />
    </div>
    <div @click="menuClose" class="menu-hider"></div>

    <!-- modals confirm redeem -->
    <div
      id="confirm-redeem-point"
      class="menu menu-box-modal rounded-m p-2"
      data-menu-width="350"
      style="display: block; width: 350px; height: auto"
    >
      <div class="d-flex p-2">
        <a href="#" @click="menuClose" class="text-danger">
          <i class="fa fa-times-circle fa-2x"></i>
        </a>
        <h1 class="ms-1 font-20 text-center">Apakah anda yakin menukarkan {{ new Intl.NumberFormat(['ban', 'id']).format(redeemSelected.nominal ?? 0) }} poin dengan {{redeemSelected.name_item}}?</h1>
        <i class="fa fa-question-circle fa-2x ms-1 text-primary"></i>
      </div>
      <div class="content mt-5">
        <div class="row mb-0">
          <div class="col-6">
            <a
              @click="menuClose"
              href="#"
              class="
                btn
                close-menu
                btn-full btn-m
                bg-red-dark
                font-600
                rounded-s
              "
            >Tidak</a
            >
          </div>
          <div class="col-6">
            <a
              @click="redeemPoint"
              href="#"
              class="
                btn
                close-menu
                btn-full btn-m
                bg-green-dark
                font-600
                rounded-s
              "
            >Iya</a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- End modals delete -->

    <!-- modals not enough point redeem -->
    <div
      id="not-enough-redeem-point"
      class="menu menu-box-modal rounded-m p-2"
      data-menu-width="350"
      style="display: block; width: 350px; height: auto"
    >
      <div class="d-flex p-2">
        <a href="#" @click="menuClose" class="text-danger">
          <i class="fa fa-times-circle fa-2x"></i>
        </a>
        <h1 class="ms-1 font-20">Poin Anda tidak cukup untuk ditukarkan dengan Item ini</h1>
        <i class="fa fa-exclamation-circle fa-2x ms-1 text-danger"></i>
      </div>
      <div class="mx-2 my-2">
        <div class="d-flex justify-content-center">
          <a
            @click="menuClose"
            href="#"
            class="btn bg-red-dark font-600"
          >Tutup</a>
        </div>
      </div>
    </div>
    <!-- End modals not enough point -->

    <!-- modals redeem success -->
    <div
      id="success-redeem-point"
      class="menu menu-box-modal rounded-m p-2"
      data-menu-width="350"
      style="display: block; width: 350px;"
    >
      <div class="menu-title p-3 d-flex">
        <i
          class="
            fa fa-check-circle
            scale-box
            float-start
            fa-3x
            color-highlight
          "
        ></i>
        <span class="fs-5 fw-bolder color-black ms-2 align-self-center">Berhasil</span>
        <a href="#" @click="menuClose" class="close-menu">
          <i class="fa fa-times-circle"></i>
        </a>
      </div>
      <div class="content">
        <div class="w-100 text-center">Silahkan tunggu maksimal 3x24 jam untuk proses penukaran poin</div>
      </div>
      <div class="content">
        <div class="row mb-3">
          <div class="col-6">
            <a
              href="#"
              class="btn bg-red-dark btn-sm btn-full"
            >Tutup</a>
          </div>
          <div class="col-6">
            <router-link :to="{ name: 'RedeemStatus' }"  class="btn bg-highlight btn-sm btn-full">
              Cek Sekarang
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- End modals delete -->

    <!-- modals redeem failed -->
    <div
      id="error-redeem-point"
      class="menu menu-box-modal rounded-m p-2"
      data-menu-height="200"
      data-menu-width="350"
      style="display: block; width: 350px;"
    >
      <div class="menu-title d-flex align-items-center">
        <i
          class="
            fa fa-exclamation-triangle
            scale-box
            float-start
            mx-3
            fa-3x
            mt-1
            color-red-dark
          "
        ></i>
        <h1 class="font-20">Gagal</h1>
        <a href="#" @click="menuClose" class="close-menu"
        ><i class="fa fa-times-circle"></i
        ></a>
      </div>
      <div class="content my-4 d-flex justify-content-center">
        {{ redeemError }}
      </div>
      <div class="content d-flex justify-content-center">
        <button @click="menuClose" class="btn bg-highlight">Oke</button>
      </div>
    </div>
    <!-- End modals redeem failed -->
  </div>
</template>

<script>
import axios from "axios";
import { init_template, menuClose, menuOpen } from "@/components/menu/index.js";
import { Toast } from "bootstrap";
import useClipboard from "vue-clipboard3";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import EmptyState from "@/components/EmptyState";

import MenuMain from "@/components/menu/MenuMain";
import MenuColors from "@/components/menu/MenuColors";
export default {
  name: "PoinSaya",
  components: {
    Header,
    Footer,
    MenuMain,
    MenuColors,
    EmptyState,
  },
  data() {
    return {
      apiEndpoint: process.env.VUE_APP_ROOT_API_NODE,
      historyPoint: [],
      redeemOptions: [],
      redeemSelected: {
        id: 0,
        name_item: '',
        nominal: ''
      },
      redeemPayload: {
        item_redeem_id: 0
      },
      redeemError: '',
      userList: [],
      isLoading: true,
      inviteLink: "",
      errors: [],
      options: [],
    };
  },
  watch: {
    skill() {
      this.addSkill();
    },
  },
  mounted() {
    init_template();
    this.isLoading = false;
    this.getListItemRedeem();
    this.getSummaryPoint();
  },
  methods: {
    menuOpen,
    menuClose,
    async copyLink() {
      const { toClipboard } = useClipboard();

      try {
        await toClipboard(this.inviteLink);
        var notificationToast = document.getElementById("toast-2");
        notificationToast = new Toast(notificationToast);
        notificationToast.show();
        console.log("url : ", axios.defaults.baseURL + this.$route.fullPath);
      } catch (e) {
        console.error(e);
      }

      /*var fullPathUrl = axios.defaults.baseURL + this.$route.fullPath;*/

      /*navigator.clipboard.writeText(fullPathUrl);*/

      /*console.log(notificationToast);*/
      /*clipboard.writeText(fullPathUrl);*/
    },
    getSummaryPoint() {
      axios
        .get("/gamification/summary-point",{
          baseURL: this.apiEndpoint,
        })
        .then(({ data }) => {
          this.historyPoint = data.data;
          console.log(this.historyPoint);
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              this.errors.push(`${property}: ${error.response.data[property]}`);
            }
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            this.errors.push("Something went wrong please try again");
            console.log(JSON.stringify(error));
          }
        });
    },
    getListItemRedeem() {
      axios
        .get('/gamification/list-item-redeem',{
          baseURL: this.apiEndpoint,
          params: {
            page: 1,
            limit: 10
          }
        })
        .then(response => {
          this.redeemOptions = response.data.data.data
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              this.errors.push(`${property}: ${error.response.data[property]}`);
            }
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            this.errors.push("Something went wrong please try again");
            console.log(JSON.stringify(error));
          }
        });
    },
    actionRedeem(data) {
      this.redeemSelected = data
      this.redeemPayload.item_redeem_id = data.id

      if (this.historyPoint?.point < this.redeemSelected.nominal) {
        this.menuOpen("not-enough-redeem-point");
      } else {
        this.menuOpen("confirm-redeem-point");
      }
    },
    redeemPoint() {
      this.menuClose("confirm-redeem-point");
      axios
        .post('/gamification/post-redeem', this.redeemPayload, {
          baseURL: process.env.VUE_APP_ROOT_API_NODE
        })
        .then(({ data }) => {
          this.menuOpen("success-redeem-point");
        })
        .catch((error) => {
          this.menuOpen("error-redeem-point");
          this.redeemError = error.response.data.message
        })
    },
  },
};
</script>

<style>
.search-box .fa-copy {
  position: absolute;
  left: 0px;
  line-height: 52px;
  padding: 0px 15px 0px 30px;
}
</style>

